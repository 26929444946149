.vpob-service-container {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff9f2;
}
.vpob-advantage-container {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vpob-service-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 354px;
  padding: 12px 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 33px 74px 0px #00000005;
}
.vpob-service-row {
  max-width: 1299px;
}
.vpob-service-link-label {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.01em !important;
  text-align: left;
}
.gst-services-advantage-heading {
  max-width: 886px;
  text-align: center;
}
.vpob-data-container {
  display: flex;
  align-items: center;
  gap: 108px;
}
.vpob-advantage-heading {
  font-family: Raleway;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: -0.04em !important;
  text-align: left !important;
  color: #12141d !important;
}
.advantage-text-container {
  max-width: 445px;
}
.vpob-advantage-text {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 27.04px !important;
  letter-spacing: -0.04em !important;
  text-align: left !important;
}
.advantage-text-container {
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .vpob-service-card {
    width: auto !important;
  }
  .gst-services-advantage-heading {
    font-size: 32px !important;
    margin-top: 0 !important;
  }
  .vpob-data-container {
    flex-direction: column;
    gap: 20px;
  }
  .vpob-advantage-img {
    width: 320px;
  }
}
