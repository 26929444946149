.client-say-container {
  min-height: 690px;
  padding-top: 100px;
  // padding-bottom: 100px;
}
.client-say-heading {
  text-align: center;
  font-weight: 400 !important;
}

.is-sticky-client-say {
  max-width: 458px;
  position: fixed;
  left: 13%;
  top: 155.5px;
}
.client-card-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.client-say-cards-container {
  display: flex;
  margin-top: 40px;

  // align-items: center;
}
div[direction="rtl"] {
  height: 0px !important;
}
.client-saying-card {
  width: 365px;
  height: 229px;
  border-radius: 10px;
  margin: 0px 12px 16px 12px;
  background-color: #fff;
  border: 1px solid #ebebec;
}
.dIKMAt,
.sc-braxZu {
  display: inline-flex !important;
}
.client-say-heading {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 0 !important;
}

.client-name {
  font-weight: 700;
}
.client-designation {
  font-weight: 500;
  font-size: 12px;
}
.client-card-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 24px;
}
.rfm-marquee {
  width: 100vw;
}

@media only screen and (max-width: 700px) {
  .client-say-container {
    min-height: auto;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    flex-direction: column-reverse;
  }

  .client-say-cards-container {
    flex-direction: column;
    margin-top: 0 !important;
  }
  .client-say-cards-container-left {
    position: static;
  }
  .client-say-cards-container-right {
    position: static;
  }
  .dYOulT {
    height: auto !important;
  }
  .client-say-heading {
    margin-top: 0 !important;
    margin-bottom: 24px !important;
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    letter-spacing: -1.5px !important;
    text-align: center;
  }
}
