.banner-container {
  background: linear-gradient(359.34deg, #fff4e7 0.53%, #fff5e8 97.56%);
  padding-top: 130px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 80px;
}
.banner-text {
  font-size: 56px !important;
  font-weight: 400 !important;
  line-height: 74px !important;
  letter-spacing: -1.5px !important;
  text-align: left;
}
.banner-consultation-upper-heading {
  font-family: Inter;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 29.05px !important;
  text-align: left !important;
}

.banner-top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-content-left {
  max-width: 50%;
}
// .banner-form {
//   margin-top: 40px;
// }
.banner-field-form-item {
  flex: 1;
  margin-right: 12px;
  width: 100%;
}
.banner-form-cta {
  // padding: 26px !important;
  border-radius: 8px !important;
  height: 56px !important;
  width: 72px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-form-wrapper {
  margin-top: 0;
  padding: 40px;
  padding-top: 25px;
  background-color: #fff;
  border-radius: 10px;
  .banner-consultation-lower-heading {
    margin-top: 0 !important;
    margin-bottom: 25px !important;
  }
  .ant-form {
    display: flex;
    // align-items: center;
    // background-color: #fff;
    justify-content: space-between;
    border-radius: 10px;
    // padding: 40px;
    margin-top: 0;
    .ant-form-item {
      margin-bottom: 0 !important;

      // width: 115% !important;
    }
  }
}
.form-ecom-chkbox-wrapper {
  display: flex;
  align-items: center;
  margin-top: 12px;
  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.1);
  }
}
.form-ecom-chkbox-wrapper-2 {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.1);
  }
}
.form-ecom-chkbox-wrapper-mobile {
  display: none;
}
.form-ecom-text {
  font-size: 16px !important;
  margin-left: 8px;
}
.form-cta {
  width: 72px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-content-right {
  width: 50%;
}
.banner-content-right-home {
  width: 50%;
  height: 516px;
  background-image: url("../../../../Assets/Images/banner-image-new.png");
  background-repeat: no-repeat;
  background-position: top;
}
.banner-consultation-upper-heading-home {
  font-family: Inter;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 29.05px !important;
  text-align: left !important;
  margin-top: 0 !important;
}
.banner-chips-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.banner-chip {
  padding: 2px 10px 2px 10px;
  box-shadow: 0px 2px 6px 0px #8f690714;
  color: #444445 !important;
  background-color: #fff;
  height: 24px;
  width: fit-content;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 6px;
  .ant-typography {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: center !important;
    color: #444445 !important;
  }
}
.ant-btn-primary:disabled {
  background: #585858 !important;
}

@media only screen and (max-width: 700px) {
  .form-ecom-chkbox-wrapper {
    display: none;
  }
  .form-ecom-chkbox-wrapper-mobile {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 16px;
    .ant-checkbox-inner,
    .ant-checkbox-input {
      transform: scale(1.1);
    }
  }
  .desktop-form-wrapper {
    .ant-form {
      padding: 24px 16px;
      flex-direction: column;
      .ant-form-item {
        margin-bottom: 16px !important;
      }
    }
  }
  .banner-consultation-upper-heading,
  .banner-consultation-upper-heading-home {
    display: none;
  }

  .banner-container {
    // background: linear-gradient(359.34deg, #fff4e7 0.53%, #fff5e8 97.56%);
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    // padding-bottom: 80px;
  }

  .banner-content-right-home {
    width: 100%;
    height: 326px;
    background-size: contain;
  }
  .banner-consultation-lower-heading {
    margin-top: 0;
    margin-bottom: 16px !important;
  }
  .banner-content-left {
    max-width: 100%;
  }
  .banner-text {
    font-family: Inter;
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 42px !important;
    letter-spacing: -1.5px !important;
    text-align: center;
  }
  .banner-top {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
  .banner-chips-container {
    justify-content: center;
  }
  .banner-content-right {
    // img {
    width: 100% !important;
    // }
  }
  .banner-form {
    // background-color: #fff;
  }
  .banner-form {
    // padding-left: 16px;
    // padding-right: 16px;

    .banner-text {
      font-size: 18px !important;
      font-weight: 500 !important;
      line-height: 21.78px !important;
      letter-spacing: 1px !important;
      text-align: left !important;
    }
    .ant-form {
      margin-top: 0 !important ;
      padding: 0 !important;
    }
  }
  .form-cta {
    width: 100% !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .banner-field-form-item {
    margin-right: 0;
  }
  .banner-cta-form-item {
    width: 100%;
    flex-direction: row-reverse;
  }
  .banner-form-cta {
    width: 100% !important;
    height: 48px !important;
    justify-content: space-between !important;
    flex-direction: row-reverse;
    padding-left: 16px;
  }
}
