.fingertips-container {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../../../Assets/Images/finger-bg.png"), #fff9f2;
}

.fingertip-cta {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 32px;
  // margin-bottom: 80px;
  height: 40px !important;
  img {
    margin-left: 11px;
  }
}

.finger-img {
  width: 100%;
}
.fingertip-text {
  max-width: 553px;
  text-align: center;
  line-height: 24px;
}

@media only screen and (max-width: 700px) {
  .fingertips-container {
    padding-top: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .fingertip-heading {
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 42px !important;
    letter-spacing: -1.5px !important;
    text-align: center;
  }
  .fingertip-text {
    font-family: Inter;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: center;
    margin-top: 16px;
  }
  .fingertip-cta {
    width: 100%;
    justify-content: center;
    height: 48px !important;
    margin-bottom: 28px;
  }
}
