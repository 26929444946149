.startup-service-conatiner,
.gst-service-conatiner {
  background-color: #f7efe5;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.startup-service-row {
  max-width: 1099px;
}
.startup-service-card {
  width: 356px;
  height: 244px;
  border: 1px solid #d4bea7;
  .ant-card-body {
    padding: 0;
  }
}
.service-card-body {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 16px;
}
.service-card-title {
  margin-left: 8px;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.01em !important;
  text-align: left;
  color: #d77d13;
}
.service-card-link {
  display: flex;
  align-items: center;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: -0.01em !important;
  text-align: left;
  margin-bottom: 8px;
  color: #2f2f30 !important;
}
.service-link-arrow {
  margin-left: 8px;
  opacity: 0;
}
.service-card-link:hover {
  color: #d77d13 !important;
  text-decoration: underline;
  .service-link-arrow {
    opacity: 1;
  }
}
.service-card-header {
  display: flex;
  align-items: center;
  background-color: #faf4ec;
  padding: 16px;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media (max-width: 767px) {
  .startup-service-conatiner {
    padding: 40px 20px;
  }
  .gst-service-conatiner {
    padding: 40px 20px;
  }
  .vpob-service-container {
    padding: 40px 20px !important;
  }
}
