.easy-process-container {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.easy-process-left {
  width: 394px;
}
.easy-process-right {
  max-width: 780px;
  margin-left: 67px !important;
}
.process-title {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.04em;
  margin-bottom: 12px;
}
.process-card {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid #f7efe5;
}
.process-bg-1 {
  background: url("../../../../Assets/Images/p1.svg");
}
.process-bg-2 {
  background: url("../../../../Assets/Images/p2.svg");
}
.process-bg-3 {
  background: url("../../../../Assets/Images/p3.svg");
}
.process-bg-4 {
  background: url("../../../../Assets/Images/p4.svg");
}
.easy-process-heading {
  font-size: 48px !important;
  font-weight: 400 !important;
  line-height: 66px !important;
  letter-spacing: -1.5px !important;
  text-align: left !important;
  margin-top: 0 !important;
}

@media only screen and (max-width: 700px) {
  .easy-process-container {
    flex-direction: column;
    padding: 40px 16px;
  }
  .easy-process-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }
  .process-bg-2 {
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  .easy-process-description {
    width: 342px;
    text-align: center;
    font-size: 12px !important;
  }
  .easy-process-right {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .easy-process-heading {
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    letter-spacing: -1.5px !important;
    text-align: center !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    width: 199px !important;
  }
  .process-card {
    padding-top: 30px;
  }
}
