.service-card {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}

.choose-plan-heading,
.choose-state-heading {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  letter-spacing: -0.04em !important;
  text-align: left;
  color: #2f2f30 !important;
}
.choose-plan-heading {
  margin-bottom: 16px;
}
.selected-card {
  border: 1px solid #d77d13;
  .service-card-heading {
    font-size: 16px !important;
    font-weight: 500 !important;
    font-weight: 500;
    line-height: 22.4px;
    font-weight: 500;
    letter-spacing: -0.04em;
    text-align: left;
    color: #2f2f30 !important;
  }
}

.individual-plan-container {
  padding: 16px 20px;
  border: 1px solid #ebebec;
  border-radius: 8px;
  margin-bottom: 8px;
}

.state-chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.plan-state-container {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border: 1px solid #ebebec;
  border-radius: 8px;
  img {
    margin-right: 8px;
  }
}

.selected-plan-state,
.selected-tenure {
  border: 1px solid #d77d13 !important;
  background: linear-gradient(90deg, #ffffff 0%, #fcf6ea 100%);
}

.selected-plan {
  border: 0.5px solid #d77d13;
  background: #fef8ed;
}

.plan-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extra-services {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.plan-state-main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;
}
.office-plan-img {
  width: 66px;
  height: 66px;
}
.unselected-card {
  background: #fbfbfb;
  border-top: 0.5px solid #ebebec;
  border-radius: 0 !important;
  .service-card-heading {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22.4px !important;
    letter-spacing: -0.04em !important;
    text-align: left;
    color: #8e8e8e !important;
  }
}

.price-info {
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
}

@media screen and (max-width: 700px) {
  .plan-state-label {
    font-size: 12px;
  }
  .choose-plan-heading {
    margin-bottom: 12px;
  }
  .selected-plan {
    background: linear-gradient(96.91deg, #ffffff 2.85%, #fcf5e9 97.58%);
  }
}
