.blog-card-date {
  margin-top: 16px;
}

.blog-preview-card-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  letter-spacing: -0.04em;
  text-align: left;
}
.content-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}
.content-imgA {
  width: 100%;
  height: 384px;
}
.content-imgB {
  width: 198px;
  height: 125px;
}
// width={singleBlog ? "100%" : "198px"}
//     height={singleBlog ? "384px" : "125px"}

.preview-live-btn {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 16px !important ;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.04em !important;
  color: #d77d13 !important;
  padding: 0 !important;
}

.preview-live-btn:hover,
.preview-live-btn:focus-visible {
  background-color: #fff !important;
  border: none !important;
  padding: 0 !important;
}

.blog-card-container {
  img {
    border-radius: 4px;
  }
}
