.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 21px;
  padding-bottom: 21px;
  position: absolute;
  z-index: 10;
  top: 0;
  width: calc(100vw - 215px);
  background-color: #f0f8ff00;
}
.desk-logo-container {
  width: 325px;
}
.mobile-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.nav-link {
  color: #8e8e8e;
  margin-right: 32px;
  font-size: 14px;
  font-weight: 500;
}
.nav-links-container {
  display: flex;
  align-items: center;
  // margin-left: ;
}

.nav-action-container {
  display: flex;
}
.login-nav-btn {
  border: 1px solid #ebebec !important;
}
.nav-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px !important;
}

@media only screen and (max-width: 1366px) {
  .nav-action-container {
    gap: 12px;
  }
  .fixed-desk-link {
    padding: 12px 16px;
    font-weight: 500 !important;
  }
}
@media only screen and (max-width: 1735px) {
  .desk-logo-container {
    width: 200px;
  }
}
@media only screen and (max-width: 700px) {
  .ant-drawer-body {
    padding-top: 24px !important;
  }
  .ant-collapse-header {
    border-radius: 0 !important;
  }
  .fixed-desk-link {
    padding: 12px 16px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 19.6px !important;
    letter-spacing: -0.04em !important;
    text-align: left;
  }
  .navbar-container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .nav-link {
    margin-right: 0px;
    margin-bottom: 10px !important;
  }
  .nav-action-container {
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    button {
      width: 100%;
    }
  }
}
