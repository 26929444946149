.startup-compliance-container {
}

.more-heading {
  font-family: Raleway !important;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-align: left;
}
