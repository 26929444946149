.services-main-selector {
  background-color: #fff;
  border-radius: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.services-selector-line,
.selected-service-line {
  display: flex;
  align-items: center;
  margin-bottom: 31px;
  padding: 15px 20px;
}
.services-selector-line {
  .service-line-icon {
    path {
      stroke: rgba(20, 20, 20, 0.4);
    }
  }
}
.selected-service-line {
  background-color: #f7efe5;
  border-left: 3px solid #d77d13;
  .service-line-icon {
    path {
      stroke: #d77d13;
    }
  }
}

.services-selector-line:hover {
  cursor: pointer;
}

.service-line-icon {
  width: 26px;
  margin-right: 10px;
}

.service-line-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: rgba(20, 20, 20, 0.4);
}

.internal-services-section-container {
  display: flex;
}
.services-secondary-container {
  background-color: #fff;
  width: 694px;
  margin-left: 16px;
  border-radius: 12px;
  border: 1px solid #ededed;
  padding: 24px;
}
.our-service-sub-line {
  display: flex;
  // justify-content: center;
  align-items: center;
  padding: 14px 16px 14px 16px;
  border-radius: 10px;
  svg {
    opacity: 0;
  }
  .ant-typography {
    color: #9d9ea0;
    font-size: 18px;
    line-height: 21.78px;
    font-weight: 600;
  }
}

.our-service-sub-line:hover {
  background-color: #f7efe5;
  cursor: pointer;
  svg {
    opacity: 1;
    margin-left: 10px;
    path {
      stroke: #d77d13;
    }
  }
  .ant-typography {
    color: #d77d13;
  }
}
