.get-started-container {
  background-color: #2f2f30;
  border-top: 1px solid #8e8e8e;
  border-bottom: 1px solid #8e8e8e;
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.get-started-left {
  max-width: 533px;
  margin-right: 155px;
}
.banner-form {
  // padding: 40px;
  // background-color: #fff;
}
.touch-text {
  font-size: 48px !important;
  font-weight: 400 !important;
  line-height: 64px !important;
  letter-spacing: -1.5px !important;
  text-align: left;
}
.get-started-description {
  margin-top: 20px;
}
.dive-text {
  font-size: 40px !important;
}
.get-started-form {
  margin-left: 40px;
  padding-bottom: 40px;
  min-width: 27%;
  box-shadow: 1px 2px 100px rgba(0, 0, 0, 0.06);
  border: none;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input {
    border: 1px solid #dbddf1;
  }
  input,
  .ant-select {
    margin-top: 24px;
    width: 100%;
  }
  button {
    width: 100%;
    margin-top: 40px;
  }
}

.get-started-form-wrapper {
  width: 552px !important;
  margin-left: 40px;
}
.consultation-form-header {
  display: none;
}
.bottom-form {
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
}
@media only screen and (max-width: 700px) {
  .get-started-container {
    flex-direction: column;
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
  }
  .get-started-form {
    margin-left: 0;
    width: 100%;
  }
  .get-started-form-wrapper {
    margin-left: 0;
    width: 100% !important;
  }
  .consultation-form-header {
    display: block;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 21.78px !important;
    text-align: left;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
  }
  .get-started-left {
    max-width: 100%;
    margin: 0;
  }
  .touch-text {
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    letter-spacing: -1.5px !important;
    text-align: center;
    width: 288px;
  }
  .banner-form {
    padding: 24px 16px 40px 16px;
    border-radius: 0;
  }
  .get-started-description {
    display: none;
  }
  .home-bottom-form-cta {
    height: 48px !important;
  }
}
