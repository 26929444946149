.faq-primary-border {
  border: 1px solid #d77d13 !important;
}
.faq-wrapper {
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.load-less-icon {
  rotate: 180deg;
}
.faq-load-more {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: -0.04em;
  text-align: center;

  img {
    margin-left: 10px;
  }
}
.faq-container {
  padding: 20px 7% 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background-color: #f7efe5;
  .faq-heading {
    font-size: 56px;
    font-weight: 500;
    line-height: 70px;
    letter-spacing: -0.04em;
    text-align: center;
    margin-bottom: 40px !important;
  }

  .faq-card-wrapper {
    display: flex;
    justify-content: space-between;
    // margin: 100px 0;
    gap: 20px;
    .faq-column {
      flex: 1;
      width: 612px;
      gap: 20px;
      display: flex;
      flex-direction: column;
    }
    .faq-card {
      border: 1px solid #e6e5f8;
      width: 100%;
      // margin-bottom: 20px;
      border-radius: 16px;

      .ant-card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 17px 24px;
        cursor: pointer;

        .content {
          width: 90%;
          text-align: left;

          .faq-card-heading {
            color: #170f49;
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: left;
          }

          .faq-card-text {
            color: #989898;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            padding-top: 10px;
          }
        }

        .icon {
          p {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            width: 28px;
            border-radius: 8px;
            background-color: #f7efe5;
            color: #d77d13;
            margin: 0 !important;
          }

          .hide {
            background-color: #d77d13;
            color: #fff;
          }
        }
      }
    }
  }
}

.faq-open {
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);
  .ant-card-body {
    padding: 20px 24px !important;
    align-items: flex-start !important;
  }
}
@media screen and (max-width: 1100px) {
  .faq-card-wrapper {
    flex-direction: column;
    gap: 0 !important;
    margin: 10% 0 !important;
    margin-top: 0 !important;
  }

  .faq-heading {
    font-size: 48px !important;
  }
}

@media screen and (max-width: 700px) {
  .faq-card-wrapper {
    width: 100% !important;
  }
  .faq-wrapper {
    min-width: 100%;
    margin-top: 0;
  }
  .faq-container {
    padding-top: 10% !important;
  }

  .faq-heading {
    font-size: 26px !important;
    font-weight: 400 !important;
    font-weight: 400 !important;
    line-height: 36px;
    font-weight: 400 !important;
    letter-spacing: -1.5px;
    text-align: center !important;
    margin: 0 !important;
    margin-bottom: 24px;
  }

  .faq-column {
    width: auto !important;
    margin-bottom: 20px;
  }
  .faq-card .ant-card-body {
    padding: 20px !important;

    .faq-card-heading {
      font-size: 16px !important;
    }

    .faq-card-text {
      font-size: 12px !important;
    }
  }
  .faq-card-wrapper {
    margin-bottom: 0 !important;
  }
}
