.payment-modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.payment-success-dialog-cta {
  width: 100%;
  margin-top: 47px;
}
.payment-declined-dialog-footer {
  margin-top: 47px;
  display: flex;
  width: 100%;
  gap: 24px;
}
.payment-declined-dialog-cta {
  width: 50%;
}

.payment-modal-title {
  margin-top: 35px;
  margin-bottom: 8px;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  letter-spacing: -0.04em !important;
  text-align: center;
}

.payment-modal-description {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19.6px !important;
  letter-spacing: -0.04em !important;
  text-align: center;
}
