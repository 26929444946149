.privacy-container {
  background: #f7efe5;
  padding-top: 139px;
  padding-bottom: 70px;
  text-align: left;
  padding-left: 100px;
  padding-right: 100px;
}

@media screen and (max-width: 720px) {
  .privacy-container {
    padding: 16px;
  }
}
