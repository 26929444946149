.internal-gst-container {
  background: #f7efe5;

  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.annual-compliance-heading {
  margin-top: 0 !important;
  margin-bottom: 0px !important;
}
.gst-services-heading {
  margin-top: 0 !important;
  margin-bottom: 64px !important;
}
.gst-i {
  position: absolute;
  bottom: 0;
  // position: absolute;
  // bottom: 0;
  // left: 30px;
}
.gst-lottie {
  z-index: 0;
  position: absolute;
  width: 500px;
  bottom: -220px;
  right: -100px;
}

.gst-semi-circle {
  background-color: #2f2f30;
  width: 126.93px;
  height: 81.6px;
  top: 88.4px;
  border-radius: 101px 101px 0px 0px;
  display: flex;
  justify-content: center;
}

.gst-arrow-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px !important;
  height: 40px !important;
  padding: 7px !important;
  background-color: #f5f5f5;
  border: none;
}
.gst-arrow-btn:hover {
  background-color: #cecece !important;
  .gst-rt-arrow {
    svg {
      path {
        stroke: #6e6e6e;
      }
    }
  }
}
.gst-rt-arrow {
  svg {
    path {
      stroke: #2f2f30;
    }
  }
}
.gst-card {
  border-left: 0;
  border-right: 0;

  .ant-card-body {
    display: flex;
    padding-bottom: 0 !important;
  }
  .gst-card-right-container {
    .ant-typography {
      width: 170px;
      font-size: 21px;
      font-weight: 700;
      margin-left: 32px;
      color: #141414;
      line-height: 32px;
    }
  }
}
.gst-service-card-container {
  max-width: 1000px;
}
.annual-compliance-description {
  width: 70vw;
  line-height: 30px;
  text-align: center;
  color: #8e8e8e !important;
}
@media (max-width: 767px) {
  .annual-compliance-description {
    line-height: 22.4px;
  }
  .gst-services-heading {
    font-size: 32px !important;
    line-height: 1.3 !important;
    margin-bottom: 18px !important;
    text-align: center;
  }
  .annual-compliance-heading {
    font-size: 32px !important;
    line-height: 1.3 !important;
    margin-bottom: 0cap !important;
    text-align: center;
  }
  .internal-gst-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .gst-card-right-container {
    .ant-typography {
      font-size: 18px !important;
      margin-left: 8px !important;
    }
  }
}
