.booking-container {
  margin-top: 80px;
  margin-bottom: 64px;
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
  border-top: 1px solid #ebebec;
  background-color: #fefdfa;
}

.booking-column {
  background-color: #fff;
  border: 1px solid #ebebec;
  padding: 24px;
  border-radius: 8px;
}

.summary-line-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px;
}
.tenures-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenure-container {
  align-items: center;
  border-radius: 8px;
  border: 1px solid #ebebec;
}
.tenure-upper {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
}

.tenure-title-container {
  display: flex;
  align-items: center;
}

.discounted-price {
  margin-left: 12px;
  text-decoration-line: line-through;
}

.tenure-extra-text-container {
  padding: 16px 20px;
  background: #fcf8f1;
  display: flex;
  align-self: center;
  border-radius: 8px;
  img {
    margin-right: 8px;
  }
  .text-primary {
    margin-right: 8px;
  }
}

// .selected-tenure {
//   border: 1px solid #d77d13;
// }
.add-on-container {
  margin-top: 16px;
}

.feature-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.savings-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-typography {
    color: #0c9d61 !important;
  }
}
.feature-title-container {
  display: flex;
  align-items: center;
  width: 100%;
  .ant-checkbox {
    margin-right: 12px !important;
  }
}
.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #d77d13 !important;
}
.add-on-section {
  margin-bottom: 16px;
}
.add-on-chkbx-container {
  display: flex;
  align-items: center;
}
.add-on-upper-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.add-on-select-field {
  width: 100%;
  margin-top: 8px;
  height: 48px !important;
}
.ant-select-selection-search-input {
  height: 100% !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.88) !important;
}
.coupon-header {
  gap: 8px;
}

.coupon-container {
  padding: 12px;
  background: #fafafa;
  border: 1px solid #ebebec;
  border-radius: 4px;
}
.coupon-body {
  align-items: center !important;
  input {
    border: none !important;
  }
}
.apply-text {
  font-size: 14px !important;
}
@media (max-width: 768px) {
  .booking-container {
    padding: 0;
    margin-top: 0;
  }
  .plan-step-heading {
    font-size: 18px !important;
    margin-bottom: 16px !important;
  }
  .ant-steps {
    justify-content: space-between !important;
  }

  .ant-steps-item-container {
    display: flex !important;
    flex-direction: column !important;
  }

  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps .ant-steps-item-title::after {
    top: -17px !important;
  }

  .ant-steps-item:last-child {
    // display: none !important;
    .ant-steps-item-container {
      .ant-steps-item-content {
        .ant-steps-item-description {
          visibility: hidden !important;
        }
        .ant-steps-item-description::before {
          visibility: visible;
          content: "Payment" !important;
        }
      }
    }
  }
  .ant-steps .ant-steps-item:last-child {
    flex: 1 !important;
  }

  .ant-steps-item {
    .ant-steps-item-description .ant-steps-item-container {
      width: 50px !important;
    }
  }
  .ant-steps-item {
    padding-inline-start: 48px;
  }

  .ant-steps-item-title {
    font-size: 8px !important;
  }
  .ant-steps-item-description {
    font-size: 12px !important;
    font-weight: 600 !important;

    color: #2f2f30 !important;
  }
  .tenure-title {
    font-weight: 500 !important;
    font-size: 16px !important;
    width: 175px;
  }
  .add-on-title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
