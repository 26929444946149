.fixed-desk-advantage-description {
  font-family: Inter;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19.6px !important;
  letter-spacing: -0.04em !important;
  text-align: left;
  color: #8e8e8e !important;
}

.fixed-desk-advant-image {
  width: 72px;
  margin-left: 24px;
}

.fixed-desk-advantage-card {
  display: flex;
  background: #ffffff;
  width: 370px;
  height: 80px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #d4bea7;
}
.fixed-desk-table-container {
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fixed-desk-table-wrapper {
  overflow: auto;
  width: 90%;
  padding: 20px;
}

@media only screen and (min-width: 701px) {
  .fixed-desk-table-wrapper {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 700px) {
  .fixed-desk-advantage-card {
    width: auto;
    height: auto;
  }
}
