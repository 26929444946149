.internal-services-container {
  background-color: #f7efe5;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.internal-services-section-container {
  margin-top: 8px;
}
.internal-services-heading {
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 64px !important;
  letter-spacing: -0.15px;
}
