.all-blogs-container {
  background: linear-gradient(180deg, #fff9f2 0%, #ffffff 100%);

  padding: 139px 100px;
}

.all-blogs-pagination-container {
  display: flex;
  justify-content: end;
  margin-top: 24px;
}
.all-blogs-heading {
  margin-bottom: 48px !important;
  text-align: center;
}
.ant-pagination-prev,
.ant-pagination-next {
  height: auto !important;
}

.view-blog-wrapper {
  display: flex;
  justify-content: center;
  padding: 100px;
}

.preview-blog-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  img {
    border-radius: 8px;
  }
}
.view-blog-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 378px;
  border-radius: 8px;
}

@media screen and (max-width: 450px) {
  .all-blogs-container,
  .view-blog-wrapper {
    padding: 20px;
  }
  .preview-blog-container {
    width: 100%;
  }
}
