.pricing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 100px;
  background-color: #2f2f30;
}
.pricing {
  font-size: 56px !important;
  font-weight: 400 !important;
  line-height: 74px !important;
  letter-spacing: -1.5px !important;
  // text-align: left;
}
.pricing-card-container {
  margin-top: 40px;
  width: 1239px;
}
.pricing-card-title {
  font-size: 20px !important;
}
.pricing-card-pointer {
  font-weight: 500;
  margin-left: 8px;
  opacity: 0.8 !important;
}
.card-month {
  opacity: 0.8 !important;
  font-weight: 300 !important;
}
.pricing-tick {
  display: block;
  path {
    fill: #fff;
  }
}

.pointer-icon-text-wrapper {
  margin-top: 20px;
}
.personalized-image {
  width: 518px;
}

.pricing-card-price {
  margin-top: 32px;
  // text-align: center;
}
.benefits-container {
  // height: 417px;
  padding: 29px 25px;
  border-radius: 0px 0px 15px 15px;
  background: #343435;
  height: 556px;
  border: 1px solid #444445;
}

.pricing-card-pricing-complete {
  font-weight: 500;
}
.plan-price {
  font-size: 56px !important;
  letter-spacing: -1px;
}
.center-price {
  letter-spacing: -1px;
  font-size: 56px !important;
  color: #483dd0 !important;
}
.center-card-btn {
  // background-color: #434ce5;
  // border-color: #434ce5;
  color: #fff;
  width: 100%;
}
.price-card-btn {
  width: 100%;
  height: 48px;
  padding: 11px 16px 11px 16px;
  border: 0.5px solid #2f2f30;
  background-color: #faf4ec;
}
.center-card-btn:hover {
  background-color: #2128aa !important;
  border-color: #2128aa !important;
  color: #fff !important;
  width: 100%;
}
.recommended-container {
  background-color: #ffad0d;
  border-radius: 8px;
  padding: 8px 11px;
  max-width: 130px;
  margin-bottom: 15px;
  .ant-typography {
    font-weight: 700;
    color: #fff !important;
    margin-left: 6px;
  }
}
.personalized-left {
  width: 527px;
}

.center-card-shadow {
  box-shadow: 0px 6px 87px rgba(38, 78, 118, 0.1);
}
.price-card-btn:hover {
  background-color: #fff;
  border-color: #2128aa !important;
  color: #2128aa !important;
  width: 100%;
}
.customised-quote-container {
  display: flex;
  padding-top: 42px;
  padding-bottom: 42px;
  padding-left: 56px;
  height: 232px;
  position: relative;
  background: url("../../../../Assets/Images/web-dots.png"),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.04) 0%,
      rgba(255, 255, 255, 0.04) 100%
    );

  // padding-bottom: 50px;
  // align-items: center;
  margin-top: 40px;
  // background-color: #fff;
  width: 1183px;
  border-radius: 16px;
  border: none;
  button {
    // width: 320px;
  }
}
.personalized-image {
  position: absolute;
  right: 0;
  bottom: 0;
}
.personalized-cta {
  padding: 12px 16px;
  background-color: #fff8e6;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  height: 48px !important;
  img {
    margin-left: 8px;
  }
}
.customized-quote-heading {
  font-family: Inter !important;
  font-size: 48px !important;
  font-weight: 500 !important;
  line-height: 58.09px !important;
  letter-spacing: -1.5px !important;
  text-align: left;
  margin-bottom: 32px !important;
}
.customize-quote-description {
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 32px !important;
  color: rgba(75, 75, 75, 0.64) !important;
}
.customised-quote-image-1 {
  position: absolute;
  top: 0;
  right: -13px;
  width: 165px;
  height: 140px;
}
.pricing-padding-container {
  padding: 25px 24px;
  background-color: #2f2f30;
  border-radius: 15px 15px 0px 0px;

  // height: 292px;
}
.benefits-container {
  background-color: #343435;
}
.customised-quote-image-2 {
  position: absolute;
  bottom: 0;
  left: -13px;
  width: 165px;
  height: 140px;
}
.pricing-card {
  width: 319px;
  // height: 770px;

  border: 1px solid #444445;
  .ant-card-body {
    padding: 0;
  }
}

@media only screen and (max-width: 700px) {
  .pricing-container {
    padding: 50px 20px 50px 20px;
  }
  .pricing-card-container {
    width: 100%;
  }
  .card-col {
    display: flex;
    justify-content: center;
  }
  .customized-quote-heading {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 33.6px !important;
    letter-spacing: -0.04em !important;
    text-align: center;
  }
  .customised-quote-container {
    // min-width: 100%;
    width: calc(100% - 40px);
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    /* align-items: center; */
    height: auto;
    justify-content: space-between;
    padding-bottom: 0;
    padding-right: 20px;
    padding-left: 20px;
    .ant-typography {
      text-align: center;
    }
  }
  .pricing {
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    letter-spacing: -1.5px !important;
    text-align: center;
  }
  .customised-quote-image-1,
  .customised-quote-image-2 {
    display: none;
  }
  .personalized-left {
    width: 100%;
  }
  .personalized-right {
    margin-top: 16px;
  }
  .personalized-image {
    position: static !important;
    width: 316px !important;
  }
  .personalized-cta {
    width: 100%;
    height: 48px !important;
    justify-content: center;
  }
}
