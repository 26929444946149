.trusted-companies-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  background-color: #fff;
  width: calc(100% - 480px);
  // margin-top: 40px;
  padding: 40px 240px;
}
.partners-container {
  display: flex;
  position: relative;
}
.partner-dot-left {
  position: absolute;
  left: 0;
  bottom: 0;
}
.partner-dot-right {
  position: absolute;
  right: 0;
  top: 0;
}
.partner-main-heading {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  letter-spacing: -1.5px !important;
  text-align: center;
}
.partner-number {
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 33.6px !important;
  letter-spacing: -1.5px !important;
  text-align: center;
}
.partner-text-span {
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 33.6px !important;
  letter-spacing: -1.5px !important;
  text-align: center;
}
.partners-left {
  width: 601px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.partners-right {
  width: 601px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.logos-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.upper-logo-container {
  margin-top: 44px;
}
.lower-logo-container {
  margin-top: 16px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fefbf6;
  padding: 15px 24px;
  margin-right: 16px;
  border: 1px solid #f7efe5;
  border-radius: 8px;
}
.partner-divider-container {
  display: flex;
  align-items: center;
}
.partner-divider {
  position: static;
  height: 268px;
  border: 0;
  border-right: 1px solid #ebebec;
}
.partner-main-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 428px;
}
.trusted-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
}
.partner-logo-img {
  width: 120px;
  mix-blend-mode: darken !important;
}
@media only screen and (max-width: 700px) {
  .trusted-companies-container {
    margin-left: 0px;
    padding: 20px;
    width: auto;
  }
  .logo-container {
    flex-direction: column;
    padding: 16px;
    img {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .partners-container {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  .partners-left {
    margin-top: 0;
  }
  .logos-wrapper {
    flex-wrap: nowrap;
  }
  .partner-logo-img {
    width: 67px;
    margin: 0 !important;
  }
  .partner-divider {
    position: static;
    height: 1px;
    width: 303px;
    border: 0;
    border-top: 1px solid #ebebec;
    margin: 0;
  }
  .partner-main-wrapper {
    height: 318px;
  }
}
