.our-blogs-container {
  padding: 100px;
  background: url("../../../../Assets/Images/scattered_dots.png"),
    linear-gradient(180deg, #fff9f2 0%, #ffffff 100%);
}

.blog-list-container {
  margin-top: 64px;
}

.blogs-rt-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.our-blogs-action-container {
  margin-top: 64px;
}

@media screen and (max-width: 450px) {
  .our-blogs-container {
    padding: 20px;
  }
}
