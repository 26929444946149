* {
  font-family: "Inter", sans-serif !important;
}

.lexend {
  font-family: "Lexend", sans-serif !important;
}
body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.stylish-text {
  position: relative;
}
.inline-block {
  display: inline-block;
}
.fancy-text {
  position: absolute;
  top: 26px;
  /* width: 177px; */
}

.d-flex {
  display: flex;
}
.row-reverse {
  flex-direction: row-reverse;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.mobile-nav-sub-heading {
  font-size: 12px !important;
  margin-bottom: 8px;
}
.mobile-nav-inner-section {
  margin-bottom: 20px;
}
.ant-select-selection-item,
.text-pure-black {
  color: #2f2f30 !important;
}
.space-between {
  justify-content: space-between;
}
.mt-1-5 {
  margin-top: 12px !important;
}
.mt-7 {
  margin-top: 56px;
}
.text-12 {
  font-size: 12px !important;
}
.text-14 {
  font-size: 14px !important;
}
.text-16 {
  font-size: 16px !important;
}
.text-18 {
  font-size: 18px !important;
}
.text-grey {
  color: #425289 !important;
}
.text-grey-2 {
  color: #4b4b4b !important;
}
.text-grey-4 {
  color: #cccccc !important;
}

.text-grey-3 {
  color: #0f2137 !important;
}
.text-white {
  color: #fff !important;
}

.text-grey-new {
  color: #4e4e4e !important;
}

.text-primary {
  color: #d77d13 !important;
}
.primary-icon {
  width: 30px;
  height: 30px;
  path {
    stroke: #d77d13;
  }
}
.font-40 {
  font-size: 40px !important;
}
.font-20 {
  font-size: 20px !important;
}
.ml-20 {
  margin-left: 20px;
}
.text-translucent {
  color: rgba(17, 20, 45, 0.64) !important;
}
.startup-popper-container {
  width: 680px;
  padding: 20px 24px;
}
.popper-container {
  width: 420px;
  padding: 20px 24px;
}

.contained-success {
  background-color: #068f2c !important;
  color: #fff !important;
  border: none;
}
.contained-white {
  background-color: #fff !important;
  color: #2f2f30 !important;
  border: none;
}
.contained-white:hover {
  background-color: #c3c1c1 !important;
  color: #5a5a5a !important;
  border: none;
}
.contained-primary {
  background-color: #2f2f30 !important;
  color: #fff !important;
  border: none;
}
.ant-drawer-body {
  padding: 0 16px !important;
  .ant-collapse-header {
    border-bottom: 1px solid #ebebec !important;
  }
}
.ant-collapse-header-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 19.6px !important;
  letter-spacing: -0.04em !important;
  text-align: left;
  color: #2f2f30 !important;
}
.contained-success:hover {
  background-color: #069b30 !important;
  border: none;
}

.extra-line {
  //styleName: Subtitle;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #8e8e8e;
}
.font-400 {
  font-weight: 400 !important;
}
.extra-line-bold {
  color: #2f2f30;
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 22.4px;
  letter-spacing: -0.04em;
  text-align: left;
}

.extra-detail-container {
  // padding-top: 100px;
  max-width: 1000px;
  padding-bottom: 56px;
}
.contact-us-extra {
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #fff;
}
.contained-primary:hover {
  background-color: #6b6b6b !important;
  border: none;
}
.fullwidth {
  width: 100%;
}
.err-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.err-contact-cta:hover {
  svg {
    path {
      stroke: #d77d13;
    }
  }
}
.font-300 {
  font-weight: 300 !important;
}
.font-600 {
  font-weight: 600 !important;
}
.text-center {
  text-align: center;
}
.err-modal-middle-text {
  margin-top: 8px;
  margin-bottom: 51px;
}
.more-sub-heading {
  font-family: Raleway !important;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-align: left;
}
.orange-style {
  // path {
  stroke: rgb(215, 125, 19) !important;
  // }
}
.ant-card-body::after,
.ant-card-body::before {
  content: none !important;
}

a {
  text-decoration: none;
}

.ml-4 {
  margin-left: 32px;
}
.ml-15 {
  margin-left: 12px;
}

.typography-reset-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.styled-text {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #2f2f30;
  color: #ffffff;
}
.ant-cascader-menu {
  border-right: none !important;
  margin-right: 24px !important;
}
.ant-cascader-panel {
  border: none;
}
.ant-cascader-menu-item {
  margin-top: 16px;
  padding: 14px 16px !important;
}
.ant-cascader-menu-item-content {
  font-weight: 600 !important;
  font-size: 18px;
  color: #9d9ea0;
  line-height: 21.78px !important;
  letter-spacing: -0.5px;
}
.ant-cascader-menu-item-active {
  background-color: #f9fafe !important;
  width: 311px;
  border-radius: 10px !important;
  font-size: 18px;
  line-height: 21.78px !important;
  .ant-cascader-menu-item-content {
    color: #434ce5 !important;
  }
}
.ant-cascader-menu-item-active {
  svg {
    path {
      fill: #434ce5;
    }
  }
}
.blue-text {
  color: #3a70e2 !important;
}

.ant-form-item-label > label.ant-form-item-required:before {
  content: unset !important;
}
.ant-cascader-menu-item:hover {
  border-radius: 10px;
}

body::-webkit-scrollbar {
  width: 0.2em;
}

body::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #d77d13;
  outline: 1px solid #d77d13;
}

button {
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px !important;
  letter-spacing: -0.5px;
  height: 56px !important;
}

.navbar-container.is-sticky {
  width: calc(100% - 200px);
  padding-bottom: 21px;
  background-color: #fff;
  position: fixed;
  box-shadow: 1px 2px 100px 0px #0000000f;

  // box-shadow: 0px 2px 24px 0px rgb(0 0 0);
}

.popper-link {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #2f2f30;
}

.popper-link:hover {
  cursor: pointer;
}
.cursor:hover {
  cursor: pointer;
}

.ant-popover-inner {
  padding: 0 !important;
}
.bold {
  font-weight: 700 !important;
}
input {
  // background-color: #fbfbfb !important;
  // color: #8e8e8e !important;
  height: 56px;
}

.ant-select {
  .ant-select-selector {
    background-color: #fbfbfb !important;
    color: #8e8e8e !important;
  }

  height: 56px !important;
}
.it-works-description,
.font-grey-2 {
  color: #8e8e8e !important;
}
.ant-tag {
  border: 1px solid #e1d3bf;
  padding: 2px 10px 2px 10px !important;
  font-size: 14px !important;
  box-shadow: 0px 2px 4px 0px #efe3d3cc;

  .ant-typography {
    color: #36364a !important;
  }
}
.go-prev-btn {
  height: 37px !important;
  margin-right: 8px;
}

@media screen and (max-width: 450px) {
  .fancy-text {
    top: 20px;
  }
  input {
    height: 48px;
  }
  .ant-select {
    height: 48px !important;
  }
  .extra-detail-container {
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1000px;
  }
}
