.internal-page-banner {
  background: #f7efe5;
  padding-top: 139px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding-left: 100px;
  padding-bottom: 130px;
}

.compliance-banner-upper-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 8px;
  display: inline-block;
  box-shadow: 0px 2px 4px 0px #efe3d3cc;
  color: #2f2f30;

  width: fit-content;
  border: 1px solid #482b0914;
}

.compliance-banner-middle-text {
  // text-align: center;
  font-size: 48px !important;
  font-weight: 400 !important;
  line-height: 64px !important;
  letter-spacing: -0.04em !important;
  max-width: 509px;
  margin-bottom: 18px !important;
}

.compliance-banner-description {
  font-size: 16px !important;
  line-height: 30px !important;
  max-width: 904px;
}

.internal-page-banner-cta {
  // width: 359px;
  padding: 15px 30px;
  margin-top: 40px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
  letter-spacing: 0.15000000596046448px !important;
  text-align: center;

  img {
    margin-left: 8px;
  }
}

.internal-page-banner-link {
  width: fit-content;
  display: block;
}
@media only screen and (max-width: 700px) {
  .compliance-banner-middle-text {
    font-size: 32px !important;
    line-height: 1.3 !important;
  }
  .compliance-banner-upper-text {
    margin-top: 0 !important;
  }
  .compliance-banner-middle-text {
    text-align: center;
    margin-top: 8px !important;
  }
  .internal-page-banner-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .compliance-banner {
    padding-top: 50px;
  }
  .internal-page-banner {
    padding: 40px 20px;
    flex-direction: column;
    img {
      width: 100%;
    }
  }
  .internal-page-banner-cta {
    width: 100%;
    justify-content: center;
    height: 48px !important;
  }
  .internal-page-banner-link {
    width: 100%;
  }
}
