.office-space-container {
  position: relative;
  background: #fff9f2;
  padding-left: 172px;
  min-height: 702px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 155.5px;
  padding-bottom: 155.5px;
}
.office-space-left {
  width: 50%;
}
.office-space-right {
  width: 50%;
  height: 250px;
}
.office-image-card-hover {
  background: linear-gradient(
    178.95deg,
    rgba(0, 0, 0, 0) 71.36%,
    #000000 101.84%
  );
}
.image-card-text-location {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 33.6px !important;
  text-align: left;
  color: #ffffff;
}
.image-card-text-office-space-text {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 18.2px !important;
  text-align: left !important;
  color: #ffffff;
}
.office-space-container {
  // position: relative;
  background: url("../../../../Assets/Images/finger-bg.png"), #fff9f2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  // padding-left: 172px;
  // min-height: 702px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 100px;
}
.white-tag {
  background-color: #ffffff !important;
  color: #2f2f30;

  padding: 2px 10px 2px 10px;
  margin-right: 12px;
  margin-bottom: 12px;
  border: 1px solid #482b0914;
  border-radius: 8px;
}
.finest-ofc-cta {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 40px !important;
  img {
    margin-left: 10px;
  }
}

.office-space-container-bottom {
  // position: -webkit-sticky;
  // position: fixed;
  position: absolute;
  left: 13%;
  bottom: 155.5px;
  max-width: 458px;
  margin-right: 107px;
}
.is-sticky-office-space {
  max-width: 458px;
  position: fixed;
  left: 13%;
  top: 155.5px;
}
.client-card-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.office-space-cards-container {
  display: flex;
  // align-items: center;
}
.office-space-cards-right-container {
  margin-left: 140px;
  height: 710px;
}
.saying-card {
  width: 430px;
  border-radius: 10px;
  margin: 20px;
  background-color: #444445;
  border: none;
}
.location-data-container {
  margin-top: 66px;
}
.location-data-section-wrapper {
  display: flex;
  align-items: center;
  // width: 256px;
  height: 80px;
  // padding-right: 24px;
  // padding-bottom: 24px;
  // padding-left: 24px;
  // padding-top: 30px;
}
.data-wrapper-0 {
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 1px solid #d7d3d3;
  border-bottom: 1px solid #d7d3d3;
}
.location-data-section-data {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  text-align: left !important;
  color: #2f2f30;
}
.swiper {
  width: 100% !important;
  height: 100% !important;
}
.location-data-section-label {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #8e8e8e !important;
}
.image-card-text-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 30px;
  width: 100%;
  // display: none;
  background: linear-gradient(
    178.95deg,
    rgba(0, 0, 0, 0) 71.36%,
    #000000 101.84%
  );
}
.data-wrapper-1 {
  justify-content: center;
  align-items: flex-start;

  border-bottom: 1px solid #d7d3d3;
}
.data-wrapper-2 {
  justify-content: flex-start;
  align-items: flex-end;
  border-right: 1px solid #d7d3d3;
}

.location-data-section-rt {
  margin-left: 16px;
}
.data-wrapper-3 {
  justify-content: center;
  align-items: flex-end;
}
.office-space-heading {
  font-size: 48px !important;
  font-weight: 400 !important;
  line-height: 66px;
  letter-spacing: -1.5px;
  text-align: left;
  margin-bottom: 12px;
  margin-top: 0 !important;
}
.office-image-card {
  position: relative;
  width: 543px;
  height: 360px;
  margin-bottom: 16px;
  border-radius: 10px;
  overflow: hidden;
}
.office-image-card-hover {
  .image-card-text-container {
    // display: block;
  }
}
.swiper-slide {
  width: 543px !important;
  height: 392px !important;
}
.client-name {
  font-weight: 700;
}
.client-designation {
  font-weight: 500;
  font-size: 12px;
}
.client-card-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.ofc-img {
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ofc-img-hover {
  transform: scale(1.2);
}
.office-space-left-container {
  position: absolute;
  left: 13%;
  top: 155.5px;
  max-width: 458px;
  margin-right: 107px;
}
.office-space-right-container {
  margin-left: 320px;
}

@media only screen and (max-width: 700px) {
  .office-space-container {
    padding-top: 40px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 40px !important;
    flex-direction: column;
  }

  .office-space-cards-container {
    flex-direction: column;
  }
  .office-space-heading {
    font-family: Inter !important;
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    letter-spacing: -1.5px !important;
    text-align: center;
  }
  .office-space-cards-container-left {
    position: static;
  }
  .office-space-cards-container-right {
    position: static;
  }
  .finest-ofc-cta {
    width: 100%;
    height: 48px !important;
    justify-content: center;
  }
  .office-space-right-container {
    margin-left: 0;
  }
  .office-space-heading {
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    letter-spacing: -1.5px !important;
    text-align: center;
    margin-bottom: 20px !important;
  }
  .office-image-card {
    width: 360px;
    height: 360px;
  }
  .office-space-cards-right-container {
    margin: 0;
    height: 420px !important;
  }
  .swiper {
    width: 360px !important;
    height: 360px !important;
    padding-bottom: 20px !important;
  }
  .swiper-pagination {
    bottom: 0px !important;
  }
  .swiper-slide {
    width: auto !important;
    // width: 0;
  }
  .swiper-pagination-bullet-active {
    background-color: #d77d13 !important;
  }
  .location-data-section-rt {
    margin-left: 8px;
  }
  .location-data-section-wrapper {
    width: 100%;
    img {
      width: 30px;
    }
  }
  .office-space-left-container {
    margin-bottom: 40px;
    position: static;
    left: 13%;
    top: 155.5px;
    max-width: 458px;
    margin-right: 0px;
  }
  .location-data-section-data {
    font-size: 16px !important;
  }
  .location-data-section-label {
    font-size: 12px !important;
  }
  .office-location-tags-container {
    text-align: center;
  }
}

// ---- old css

// .office-space-container-bottom {
//   // position: -webkit-sticky;
//   // position: fixed;
//   position: absolute;
//   left: 13%;
//   bottom: 155.5px;
//   max-width: 458px;
//   margin-right: 107px;
// }
.is-sticky-client-say {
  max-width: 458px;
  position: fixed;
  left: 13%;
  top: 155.5px;
}
.client-card-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.client-say-cards-container {
  display: flex;
  // align-items: center;
}

// .saying-card {
//   width: 430px;
//   border-radius: 10px;
//   margin: 20px;
//   background-color: #444445;
//   border: none;
// }

.client-say-heading {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 12px;
}

.client-name {
  font-weight: 700;
}
.client-designation {
  font-weight: 500;
  font-size: 12px;
}
.client-card-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (max-width: 700px) {
  .client-say-container {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column-reverse;
  }
  .client-say-cards-container {
    flex-direction: column;
  }
  .client-say-cards-container-left {
    position: static;
  }
  .client-say-cards-container-right {
    position: static;
  }
}
