.contact-us-basic-banner {
  background: #f7efe5;
  padding-top: 139px;
  display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  text-align: left;
  padding-left: 100px;
  // padding-bottom: 130px;
}
.contact-us-basic-banner-right {
  // background: url("../../Assets/Images/new-form-bg.png");
  width: calc(100% - 523px);
  display: flex;
  align-items: end;
  justify-content: center;
  height: 100%;
}
.contact-us-basic-banner-left {
  max-width: 523px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-us-form {
  padding: 40px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.contact-us-2-form-wrapper {
  position: relative;
  z-index: 2;
}

.internal-form-bg {
  position: absolute;
  bottom: 0;
  right: -18%;
  width: 775px;
  z-index: -1;
}

@media only screen and (max-width: 700px) {
  .contact-us-basic-banner {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    padding-top: 50px;
  }
  .contact-us-basic-banner-left {
    padding-left: 20px;
    padding-right: 20px;
    .banner-description {
      text-align: center;
    }
  }
  .contact-us-basic-banner-right {
    width: 100%;
    margin-top: 16px;
  }
  .contact-us-basic-form {
    padding: 24px 16px;
  }
}
