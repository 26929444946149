.contact-us-container {
  background: linear-gradient(359.34deg, #fff4e7 0.53%, #fff5e8 97.56%);
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-banner-container {
  background: linear-gradient(359.34deg, #fff4e7 0.53%, #fff5e8 97.56%);
  // padding-top: 130px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 80px;
  width: calc(100% - 200px);
}
.contact-us-banner {
  display: flex;
  flex-direction: column;

  // align-items: center;
}
.rest-trusted {
  width: 100%;
  .trusted-companies-container {
    width: auto;
  }
}
.body2-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #fff;
}
.body-2-sub-container {
  width: 1000px;
}
.customer-support-container {
  display: flex;
  justify-content: space-between;
}
.contact-us-sub {
  font-size: 32px;
  font-weight: 600;
  color: #373534;
}
.contact-us-sub-desc {
  font-size: 20px;
  width: 678px;
  color: rgba(0, 0, 0, 0.6);
}

.support-place {
  font-size: 16px;
}
.support-address-container {
  width: 397px;
}

.support-address-line-1 {
  font-weight: 500;
}

.address-light {
  color: rgba(0, 0, 0, 0.6);
}

.number-wrapper {
  display: flex;
  justify-content: space-between;
}

.number-heading {
  font-size: 16px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}
.contact-info-text {
  font-size: 16px;
  font-weight: 500;
  max-width: 397px;
}
.contact-info-headings {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16.8px !important;
  letter-spacing: -0.04em;
  text-align: left;
  color: #656566 !important;
}

.body-2-cub-container {
  width: 861px;
}
.internal-page-table-container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.internal-table-wrapper {
  overflow: auto;
  width: 100%;
  padding: 20px;
}

@media only screen and (min-width: 701px) {
  .internal-table-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 700px) {
  .contact-us-container {
    padding-top: 50px;
  }

  .contact-us-banner {
    text-align: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .contact-us-sub-desc {
    width: 100%;
  }
  .body-2-sub-container {
    width: 88%;
    padding-left: 18px;
    padding-right: 18px;
  }

  .contact-banner-container {
    // background: linear-gradient(359.34deg, #fff4e7 0.53%, #fff5e8 97.56%);
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    width: 100%;
    // padding-bottom: 80px;
  }
  .banner-content-left {
    width: 100% !important;
  }

  // .body2-container {
  //   padding-left: 15px;
  //   padding-right: 15px;
  // }
  .number-wrapper {
    justify-content: space-between;
    flex-direction: column;
  }
  .customer-support-container {
    flex-direction: column;
  }
  .support-address-container {
    width: auto;
  }
  .last-support-address-container {
    width: auto;
    margin-top: 24px;
  }
  .services-section-container {
    width: 89%;
    padding: 24px;
    flex-direction: column;
  }
  .services-secondary-container {
    margin-top: 25px;
    width: 86%;
    margin-left: 0;
  }
  .gst-service-card-container {
    padding-top: 72px;
    margin: 0 !important;
    .ant-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .internal-table-wrapper {
    width: 90vw;
    padding: 20px;
  }
}
